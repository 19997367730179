/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { TypeLatLng } from './TypeLatLng';
import {
    TypeLatLngFromJSON,
    TypeLatLngFromJSONTyped,
    TypeLatLngToJSON,
} from './TypeLatLng';

/**
 * 
 * @export
 * @interface V1SearchAutoCompleteResultLocalProfile
 */
export interface V1SearchAutoCompleteResultLocalProfile {
    /**
     * 
     * @type {string}
     * @memberof V1SearchAutoCompleteResultLocalProfile
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof V1SearchAutoCompleteResultLocalProfile
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof V1SearchAutoCompleteResultLocalProfile
     */
    branchName: string;
    /**
     * 
     * @type {string}
     * @memberof V1SearchAutoCompleteResultLocalProfile
     */
    categoryName: string;
    /**
     * 
     * @type {number}
     * @memberof V1SearchAutoCompleteResultLocalProfile
     */
    calculatedDistanceMeter?: number;
    /**
     * 
     * @type {TypeLatLng}
     * @memberof V1SearchAutoCompleteResultLocalProfile
     */
    latlng?: TypeLatLng;
}

/**
 * Check if a given object implements the V1SearchAutoCompleteResultLocalProfile interface.
 */
export function instanceOfV1SearchAutoCompleteResultLocalProfile(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "branchName" in value;
    isInstance = isInstance && "categoryName" in value;

    return isInstance;
}

export function V1SearchAutoCompleteResultLocalProfileFromJSON(json: any): V1SearchAutoCompleteResultLocalProfile {
    return V1SearchAutoCompleteResultLocalProfileFromJSONTyped(json, false);
}

export function V1SearchAutoCompleteResultLocalProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1SearchAutoCompleteResultLocalProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'branchName': json['branch_name'],
        'categoryName': json['category_name'],
        'calculatedDistanceMeter': !exists(json, 'calculated_distance_meter') ? undefined : json['calculated_distance_meter'],
        'latlng': !exists(json, 'latlng') ? undefined : TypeLatLngFromJSON(json['latlng']),
    };
}

export function V1SearchAutoCompleteResultLocalProfileToJSON(value?: V1SearchAutoCompleteResultLocalProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'branch_name': value.branchName,
        'category_name': value.categoryName,
        'calculated_distance_meter': value.calculatedDistanceMeter,
        'latlng': TypeLatLngToJSON(value.latlng),
    };
}

