import { IS_LOCAL, IS_PRODUCTION } from '@src/constants'
import { SESSION_AUTH_TOKEN, getSessionStore } from '@src/store/sessionStore'
import { extractAppBuildType } from '@src/ts-utils/userAgent'

const debugAppLogger = (userAgent: string) => {
  /**
   * debug - Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 TowneersApp/24.22.0 (242200; iOS 17.5.1; Alpha; debug)
   * release - Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 TowneersApp/24.26.0 (242600; iOS 17.5.1; Production; release)
   */
  const IS_DEBUG_APP = extractAppBuildType(userAgent) === 'debug'

  return {
    log: IS_DEBUG_APP ? console.log : function () {},
    error: IS_DEBUG_APP ? console.error : function () {},
  }
}

const POSSIBLE_LOG =
  IS_LOCAL ||
  !IS_PRODUCTION ||
  debugAppLogger(window.navigator.userAgent) ||
  getSessionStore(SESSION_AUTH_TOKEN)

const logLevel = {
  log: POSSIBLE_LOG ? console.log.bind(window.console) : function () {},
  info: POSSIBLE_LOG ? console.info.bind(window.console) : function () {},
  warn: POSSIBLE_LOG ? console.warn.bind(window.console) : function () {},
  error: POSSIBLE_LOG ? console.error.bind(window.console) : function () {},
}

export { logLevel, debugAppLogger }
